// const SportsMaintenance = "/assets/banner/home/Maintenance.webp"
// const SportsEventImgUrl = "/assets/banner/home/369.webp"
// const SportsCashbackImgUrl = "/assets/banner/home/SportsMulti.webp"
// const EvolutionCashbackImgUrl = "/assets/banner/home/EvolutionCashback.webp"
// const DailyBonusImgUrl = "/assets/banner/home/DailyDeposit10.webp"
// const FirstDepositImgUrl = "/assets/banner/home/registerbonus.webp"
// const MultiBetImgUrl = "/assets/banner/home/MultibetCashback.webp"
// const CashoutImgUrl = "/assets/banner/home/Cashout01.webp"
// const LevelRewardsImgUrl = "/assets/banner/home/Levelup.webp"
// const SportsBonusImgUrl = "/assets/banner/home/sportsbonus.webp"
// const ReferFriend = "/assets/banner/home/ReferaFriend.webp"
// const PayBack = "/assets/banner/home/Payback.webp"
// const SecondDeposit = "/assets/banner/home/SecondDeposit.webp"

//Mobile
// const SmallSportsMaintenance = "/assets/banner/home/small/Maintenance.webp"
// const SmallSportsEventImgUrl = "/assets/banner/home/small/369.webp"
// const SmallSportsCashbackImgUrl = "/assets/banner/home/small/SportsMulti.webp"
// const SmallEvolutionCashbackImgUrl =
//   "/assets/banner/home/small/EvolutionCashback.webp"
// const SmallDailyBonusImgUrl = "/assets/banner/home/small/DailyDeposit10.webp"
// const SmallFirstDepositImgUrl = "/assets/banner/home/small/registerbonus.webp"
// const SmallMultiBetImgUrl = "/assets/banner/home/small/MultibetCashback.webp"
// const SmallCashoutImgUrl = "/assets/banner/home/small/Cashout01.webp"
// const SmallLevelRewardsImgUrl = "/assets/banner/home/small/Levelup.webp"
// const SmallSportsBonusImgUrl = "/assets/banner/home/small/sportsbonus.webp"
// const SmallReferFriend = "/assets/banner/home/small/ReferaFriend.webp"
// const SmallPayBack = "/assets/banner/home/small/Payback.webp"
// const SmallSecondDeposit = "/assets/banner/home/small/SecondDeposit.webp"
import {Suspicious, SmallSuspicious} from "screens/Home/MainV2/assets"

export const POPUP_BANNER_TYPES = {
  SPORTS_EVENT: "sports_event",
  SPORTS_CASHBACK: "sports_cashback",
  EVO_CASHBACK: "evolution_cashback",
  DAILY_BONUS: "daily_bonus",
  FIRST_DEPOSIT: "first_deposit",
  MULTIBET_REFUND: "multibet_refund",
  CASH_OUT: "cash_out",
  LVL_UP_REWARDS: "lvl_up_rewards",
  REFER_FRIEND: "refer_friend",
  PAY_BACK: "pay_back",
  SECOND_DEPOSIT: "second_deposit",
  SPORTS_BONUS: "sports_bonus",
}

export const HOME_BANNERS = [
  {
    name: "suspicious",
    assets: {
      l: Suspicious,
      s: SmallSuspicious,
    },
    // name: "sports_maintenance",
    // assets: {
    //   l: SportsMaintenance,
    //   s: SmallSportsMaintenance,
    // },
    // },
    // {
    //   name: "first_deposit",
    //   assets: {
    //     l: FirstDepositImgUrl,
    //     s: SmallFirstDepositImgUrl,
    //   },
    // },
    // {
    //   name: "second_deposit",
    //   assets: {
    //     l: SecondDeposit,
    //     s: SmallSecondDeposit,
    //   },
    // },
    // {
    //   name: "daily_bonus",
    //   assets: {
    //     l: DailyBonusImgUrl,
    //     s: SmallDailyBonusImgUrl,
    //   },
    // },
    // {
    //   name: "pay_back",
    //   assets: {
    //     l: PayBack,
    //     s: SmallPayBack,
    //   },
    // },
    // {
    //   name: "refer_friend",
    //   assets: {
    //     l: ReferFriend,
    //     s: SmallReferFriend,
    //   },
    // },
    // {
    //   name: "sports_event",
    //   assets: {
    //     l: SportsEventImgUrl,
    //     s: SmallSportsEventImgUrl,
    //   },
    // },
    // {
    //   name: "sports_cashback",
    //   assets: {
    //     l: SportsCashbackImgUrl,
    //     s: SmallSportsCashbackImgUrl,
    //   },
    // },
    // {
    //   name: "evolution_cashback",
    //   assets: {
    //     l: EvolutionCashbackImgUrl,
    //     s: SmallEvolutionCashbackImgUrl,
    //   },
    // },
    // {
    //   name: "sports_bonus",
    //   assets: {
    //     l: SportsBonusImgUrl,
    //     s: SmallSportsBonusImgUrl,
    //   },
    // },
    // {
    //   name: "multibet_refund",
    //   assets: {
    //     l: MultiBetImgUrl,
    //     s: SmallMultiBetImgUrl,
    //   },
    // },
    // {
    //   name: "cash_out",
    //   assets: {
    //     l: CashoutImgUrl,
    //     s: SmallCashoutImgUrl,
    //   },
    // },
    // {
    //   name: "lvl_up_rewards",
    //   assets: {
    //     l: LevelRewardsImgUrl,
    //     s: SmallLevelRewardsImgUrl,
    //   },
  },
]

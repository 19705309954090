// const Suspicious = "/assets/banner/home/Suspicious02.webp"

import {MaintenanceBanner, SmallMaintenanceBanner} from "screens/Home/MainV2/assets"

export const POPUP_BANNER_TYPES = {
  NEWSPORTS: "maintenance",
}

export const HOME_BANNERS = [
  {
    // name: "suspicious",
    // assets: {
    //   l: Suspicious,
    //   s: SmallSuspicious,
    // },
    name: "maintenance",
    assets: {
      l: MaintenanceBanner,
      s: SmallMaintenanceBanner,
    },
  },
]

// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useState, useEffect, useContext} from "react"
import {colorPalette as cp} from "utils/theme/colors"
import {isIOS} from "react-device-detect"
import Fade from "react-reveal/Fade"
/**
 * Components
 */
import IconExclamation from "components/Icons/Forms/Exclamation"
import Loader from "components/Loader"
/**
 * Styles
 */
import s from "./referrer.module.css"
import {ThemeContext} from "context/ThemeContext"

interface IFormReferrerInput {
  name: string
  label: string
  value?: string | undefined
  labelHelper?: string
  placeholder?: string
  isValidReferrer: boolean
  isSubmitting?: boolean
  register: any
  errors?: any
  onChange?: any
  onFocus?: () => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

const FormInput = ({
  label,
  name,
  placeholder,
  labelHelper,
  errors,
  value,
  onChange,
  onFocus,
  onBlur,
  isValidReferrer = true,
  isSubmitting = false,
  register,
}: IFormReferrerInput): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const [isFocused, setFocus] = useState(false)
  const [messageHeight, setMessageHeight] = useState<number>(0)
  const hasError = errors !== undefined && errors
  const isValid = !hasError && value !== ""
  const validOther =
    !isSubmitting && !isFocused && !hasError && isValidReferrer && value !== ""

  function handleFocus() {
    return [setFocus(true), onFocus && onFocus()]
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    setMessageHeight(0)
    if (name === "referrer" && value === "") {
      const code = localStorage.getItem("REFERRAL_CODE")
      const defaultCode = code ? code : ""
      e.target.value = defaultCode
      return [setFocus(false), onChange(defaultCode), onBlur && onBlur(e)]
    }

    return [setFocus(false), onBlur && onBlur(e)]
  }

  const borderClass = `
    ${s.inputBordered}
    ${hasError ? s.inputBorderedError : ``}
    ${isFocused ? s.inputBorderedActive : ``}
    ${isValid ? s.inputBorderedValid : ``}
  `

  const labelClass = `
    ${s.labelInfo}
    ${isFocused ? s.labelInfoActive : ``}
    ${hasError ? s.labelInfoError : ``}
    ${isValid ? s.labelInfoValid : ``}
  `

  const messageClass = `
    ${s.referrerMessage} 
  `
  const messageStyle = {
    height: isFocused && !hasError ? messageHeight : hasError ? "43px" : "28px",
  }

  useEffect(() => {
    const messageIdGreen = document.getElementById("message-green")
    const messageIdBlue = document.getElementById("message-blue")
    setMessageHeight(messageIdGreen.clientHeight + messageIdBlue.clientHeight + 15)
  }, [isFocused])

  return (
    <div className={s.wrapperReferrer}>
      <div className={borderClass}>
        <div className={s.labelContainer}>
          <div className={s.label}>{label}</div>
          {labelHelper && (
            <div className={labelClass}>
              <span className="message">
                <span className={s.parenStyle}>{`( `}</span>
                {labelHelper}
                <span className={s.parenStyle}>{` )`}</span>
              </span>
            </div>
          )}
        </div>
        <input
          key={label}
          className={isIOS ? s.formInputIOS : s.formInput}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          name={label}
          value={value}
          type="text"
          maxLength={14}
          onFocus={() => handleFocus()}
          onBlur={e => handleBlur(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            if (value.length >= 14) {
              onChange(value.substring(0, 14))
              return false
            } else {
              onChange(value.replace(/[^A-Za-z0-9]/gi, ""))
            }
          }}
          ref={register}
          placeholder={placeholder}
        />
      </div>

      {isSubmitting && (
        <div style={{position: "absolute", top: "3px", right: 0, zIndex: 1}}>
          <Loader />
        </div>
      )}

      <div>
        <div className={messageClass} style={messageStyle}>
          <Fade top opposite when={validOther}>
            <div
              className={`${s.referrerHelper} `}
              id="referrer-success-other-code"
              style={{
                height: validOther ? "38px" : "0px",
              }}
            >
              <div className={s.referrerHelperTextBlue} id="message-blue">
                <div className={s.spaceLeft}>{`사용가능한 추천인코드 입니다.`}</div>
              </div>
            </div>
          </Fade>

          <Fade top opposite distance="30px" when={isFocused && !hasError}>
            <div id="referrer-success-other-code" className={s.referrerHelper}>
              <div className={s.referrerHelperTextGreen} id="message-green">
                <div className={s.spaceLeft}>
                  지인, 총판 및 기타 코드를 알고 계시다면 입력해 주시기 바랍니다.
                </div>
              </div>
              <div className={s.referrerHelperTextBlue} id="message-blue">
                <div className={s.spaceLeft}>
                  코드 입력이 없을시 자동으로 코드가 입력되어 진행 됩니다.
                </div>
              </div>
            </div>
          </Fade>

          <Fade top opposite when={!isSubmitting && !isFocused && hasError}>
            <div id="referrer-success-other-code" className={s.referrerHelper}>
              <span className={s.referrerHelperTextError} id="message-red">
                <div className={s.icon}>
                  <IconExclamation color={theme.colors.inputFieldErrorColor} />
                </div>
                <span className={s.spaceLeft} style={{paddingLeft: "4px"}}>
                  유효하지 않은 코드 입니다
                </span>
              </span>
              <span className={s.referrerHelperTextBlue}>
                <div className={s.icon}>
                  <IconExclamation color={cp.aquablue} />
                </div>
                <span className={s.spaceLeft} style={{paddingLeft: "4px"}}>
                  전체 삭제시 자동으로 코드가 입력되어 집니다.
                </span>
              </span>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default FormInput

import React, {FC, memo, useEffect, useState, useCallback} from "react"
import ReactModal from "react-modal"
import {useWindowSize} from "react-use"
import cx from "classnames"
import usePopupBannerV3Store from "components/Banner/PopupV3/usePopupBannerStore"
import s from "./modal.module.scss"
import shallow from "zustand/shallow"

interface iModalBanner {
  className?: string
  children?: React.ReactNode
  handleExit?: () => void
  handleQuickExit?: () => void
}

const ModalBanner: FC<iModalBanner> = ({
  className,
  children,
  handleExit,
  handleQuickExit,
}): JSX.Element => {
  const [setIsClosing] = usePopupBannerV3Store(
    store => [
      store.setIsClosing,
    ],
    shallow,
  )
  const [isOpen, setIsOpen] = useState(false)
  const {height} = useWindowSize()
  useEffect(() => {
    document.body.style.overflow = `hidden`
    document.body.style.height = `100vh`
    setTimeout(() => {
      setIsOpen(true)
    }, 50)
    return () => document.body.removeAttribute(`style`)
  }, [])

  const handleClose = useCallback((): void => {
    setIsOpen(false)
    handleQuickExit()
    setTimeout(() => {
      handleExit()

      // const ignoredV4Date = localStorage.getItem("home_banner_v4_ignore")
      // const koreanDateNow = changeTimeZone(new Date(), "Asia/Seoul")
      // const dateNow = format(koreanDateNow, "yyyy-MM-dd")

      // if (dateNow !== ignoredV4Date) {
      //   const banner = usePopupBannerV4Store.getState()
      //   banner.setIsOpen(true)
      // } 
    }, 300)
  }, [setIsOpen, handleExit])

  useEffect(() => {
    const popup = usePopupBannerV3Store.getState()
    popup.setClose(handleClose)
  }, [handleClose])

  return (
    <ReactModal
      ariaHideApp={false}
      className={s.container}
      id={`banner-modal`}
      isOpen
      style={{
        content: {
          minHeight: `${height}px`,
        },
      }}
      overlayClassName={cx(s.overlay, {
        [s.show]: isOpen,
      })}
    >
      <div className={s.overlayBlanket} onClick={() => setIsClosing(true)} />
      <div
        className={cx(s.modalWrapper, className, {
          [s.show]: isOpen,
        })}
      >
        {children && children}
      </div>
    </ReactModal>
  )
}

export default memo(ModalBanner)

import i18n from "i18next"
import {initReactI18next} from "react-i18next"
// import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
// import en from "locales/en/translation.json"
// import kr from "locales/kr/translation.json"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  // .use(LanguageDetector)
  .init({
    lng: "kr",
    fallbackLng: "kr",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_BUILD_VERSION}`,
    },
  })

// i18n.addResourceBundle("en", "translation", en)
// i18n.addResourceBundle("kr", "translation", kr)

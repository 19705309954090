import React, {FC, memo, useMemo} from "react"
import cx from "classnames"
import s from "./pieChart.module.scss"

interface iPieChart {
  className?: string
  size?: number
  strokeWidth?: number
  value: number
  totalValue: number
  containerColor?: string
  progressColor?: string
}

const PieChart: FC<iPieChart> = ({
  className,
  size = 53,
  strokeWidth = 4,
  value,
  totalValue,
  containerColor = "#5A5A5A",
  progressColor = "#AD23D6",
}) => {
  const circle = useMemo(() => {
    return (size - strokeWidth) * 3.14
  }, [size, strokeWidth])

  const percentage = useMemo(() => {
    if (totalValue > 0) return value / totalValue
    else return 0
  }, [value, totalValue])

  const displayValue = useMemo(() => {
    const maxValue = value + 1
    if (value === totalValue) return totalValue
    if (value !== totalValue) return value
    else if (maxValue > totalValue) return totalValue
    else return maxValue
  }, [value, totalValue])

  const progress = useMemo(() => {
    if (displayValue === 0 || displayValue === 1) return 0
    else if (displayValue === totalValue) return circle
    else return percentage * circle
  }, [displayValue, totalValue, percentage, circle])

  return (
    <div className={cx(s.container, className)}>
      <figure
        style={{width: size, height: size}}
        className={cx(s.figure, s.animate)}
      >
        <svg role="img" xmlns="http://www.w3.org/2000/svg">
          <circle
            r={(size - strokeWidth) / 2}
            strokeWidth={strokeWidth}
            className={s.circleBg}
            stroke={containerColor}
            style={{
              stroke: containerColor,
            }}
          />
          <circle
            r={(size - strokeWidth) / 2}
            strokeWidth={strokeWidth}
            strokeDasharray={`${progress}px ${circle}px`}
            className={cx(s.circleFg, {
              [s.noTransition]: displayValue === totalValue,
            })}
            style={{
              stroke: progressColor,
            }}
          />
        </svg>
      </figure>
      <div className={s.content}>
        <span className={s.text}>{displayValue}</span>
      </div>
    </div>
  )
}

export default memo(PieChart)

import React, {FC, memo} from "react"
import s from "./popup.module.scss"
import Layout from "components/Banner/PopupV3/Layout"
import CloseTimerButton from "components/Banner/PopupV3/CloseTimerButton"
import { useWindowSize } from "react-use"
interface iPopup {
  className?: string
  selectedPage?: string
}

const Popup: FC<iPopup> = (): JSX.Element => {
  const {width} = useWindowSize()
  const isMobile = width < 674
  return (
    <div className={s.container}>
      <Layout />
      {isMobile && <CloseTimerButton/>}
    </div>
  )
}

export default memo(Popup)

// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import {QueryClient, QueryCache, QueryClientProvider} from "react-query"
import {Provider} from "jotai"
import {ModalProvider} from "react-modal-hook"
import {ParallaxProvider} from "react-scroll-parallax"
import {BrowserRouter as Router} from "react-router-dom"
import "./index.scss"
import "react-date-range/dist/styles.css"
import LandingPage from "components/LandingPage"
import "./i18n"
import App from "App"
import {ThemeProvider} from "context/ThemeContext"

// enable this when testing on mobiple phone
// if (process.env.REACT_APP_NODE_ENV !== "production") {
// provides debugging tool on mobile devices
// outfront()
// }

const queryCache = new QueryCache()
const queryClient = new QueryClient({queryCache})

// this will force every image to be refetched when REACT_APP_BUILD_VERSION is changed
// its necessary to make sure images not using cached versions.
// disabling it for now
// const originalCreateElement = document.createElement
// document.createElement = function (tagName) {
//   const element = originalCreateElement.call(document, tagName)

//   // only append to image and video elemnts
//   if (tagName === "img" || tagName === "video") {
//     const originalSetAttribute = element.setAttribute
//     element.setAttribute = function (attrName, value) {
//       if (attrName === "src" && value) {
//         const buildVersion = process.env.REACT_APP_BUILD_VERSION || "1.0.0"
//         // if there was already query at the image, append query,
//         value += value.includes("?") ? `&v=${buildVersion}` : `?v=${buildVersion}`
//       }
//       originalSetAttribute.call(this, attrName, value)
//     }
//   }

//   return element
// }

ReactDOM.render(
  <Suspense fallback={<LandingPage />}>
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <ParallaxProvider>
            <Router>
              <ModalProvider>
                <App />
              </ModalProvider>
            </Router>
          </ParallaxProvider>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById("root"),
)

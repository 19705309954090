import create from "zustand"
import Flicking from "@egjs/react-flicking/declaration/Flicking"
import {POPUP_BANNER_TYPES} from "./constants"
import {iniKoreantMidnight} from "screens/Casino/utils/helper"

export interface iPopupBannerStore {
  sliderRef: Flicking
  isOpen?: boolean
  timer: number
  countDown: number
  index: number
  type: string
  isOnHold: boolean
  secsToMidnight: number
  isClosing: boolean
  closeTimer: number
  setSecsToMidnight: (value: number) => void
  setIsOnHold: (value: boolean) => void
  setIsClosing: (value: boolean) => void
  setCloseTimer: (value: number) => void
  close: () => void
  setSliderRef: (value: Flicking) => void
  setIsOpen?: (value: boolean) => void
  setTimer: (value: number) => void
  setCountDown: (value: number) => void
  setIndex: (value: number) => void
  setType: (value: string) => void
  setClose: (value: () => void) => void
  timerTick: () => void
  midnightTick: () => void
  closeTick: () => void
  resetStore: () => void
  resetTimer: () => void
}

const usePopupBannerStore = create<iPopupBannerStore>((set, get) => ({
  sliderRef: null,
  isOpen: false,
  timer: 5,
  countDown: 5,
  index: 0,
  type: POPUP_BANNER_TYPES.NEWSPORTS,
  isOnHold: false,
  close: null,
  isClosing: false,
  closeTimer: 2,
  secsToMidnight: iniKoreantMidnight(),
  setCloseTimer: closeTimer => set({closeTimer}),
  setIsClosing: isClosing => set({isClosing}),
  setSecsToMidnight: secsToMidnight => set({secsToMidnight}),
  setIsOnHold: isOnHold => set({isOnHold}),
  setSliderRef: sliderRef => set({sliderRef}),
  setIsOpen: isOpen => set({isOpen}),
  setTimer: timer => set({timer}),
  setCountDown: countDown => set({countDown}),
  setIndex: index => set({index}),
  setType: type => set({type}),
  setClose: close => set({close}),
  midnightTick: () => {
    const secsToMidnight = get().secsToMidnight
    const second = secsToMidnight - 1
    if (second >= 0) set({secsToMidnight: second})
    else set({secsToMidnight: 60 * 60 * 24})
  },
  timerTick: () => {
    const timer = get().timer
    const second = timer - 1
    if (second >= 0) set({timer: second})
    else {
      const countDown = get().countDown
      const slider = get().sliderRef
      setTimeout(() => slider.next(), 1000)
      set({timer: countDown})
    }
  },
  closeTick: () => {
    const timer = get().closeTimer
    const second = timer - 1
    if (second >= 1) set({closeTimer: second})
    else {
      set({closeTimer: 0})
      get().close()
    }
  },
  resetStore: () => {
    set({
      closeTimer: 2,
      isClosing: false,
      isOpen: false,
      timer: 5,
      countDown: 5,
      index: 0,
      isOnHold: false,
      type: POPUP_BANNER_TYPES.NEWSPORTS,
    })
  },
  resetTimer: () => {
    const countDown = get().countDown
    set({
      timer: countDown - 1,
    })
  },
}))

export default usePopupBannerStore

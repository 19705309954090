import React, {FC, memo} from "react"
import shallow from "zustand/shallow"
import {iconClose} from "screens/Home/MyPage/Screens/BetHistory/components/BetDetails/icons"
import usePopupBannerStore from "components/Banner/PopupV3/usePopupBannerStore"
import {HOME_BANNERS} from "components/Banner/PopupV3/constants"
import s from "./layout.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"
// const Slider = lazyWithRetry(() => import("components/Banner/PopupV3/Slider"))
import {WholeBannerImage2} from "screens/Home/MainV2/assets"
import {useWindowSize} from "react-use"
import CloseTimerButton from "components/Banner/PopupV3/CloseTimerButton"

interface iLayout {
  className?: string
  selectedPage?: string
}

const Layout: FC<iLayout> = (): JSX.Element => {
  const [setIsClosing] = usePopupBannerStore(store => [store.setIsClosing], shallow)
  const {width} = useWindowSize()
  const isMobile = width < 674

  return (
    <>
      {!isMobile && <CloseTimerButton />}
      <div className={s.container}>
        {/* <Slider items={BANNERS} /> */}
        <img
          className={s.bannerImage}
          src={WholeBannerImage2}
          draggable={false}
          onClick={() => setIsClosing(true)}
        />
      </div>
      {/* <div
        role="button"
        className={s.redirectBtn}
        onClick={() => handleOnClick(PROMOTIONS)}
      >
        <span>자세한 내용은 이벤트 페이지에서 확인해주세요</span>
      </div> */}
    </>
  )
}

export default memo(Layout)

import {HAS_PENDING_OTP} from "screens/MyProfile/constants"

export const parseCategory = (category: string) => {
  switch (category) {
    case "PLAYER_WITHDRAW":
      return "환전신청"
    case "PLAYER_CHANGE_MOBILE_NUMBER":
      return "휴대폰 번호 변경"
    case "PLAYER_CHANGE_PASSWORD":
      return "비밀번호변경"
    case "PLAYER_BANK_ACCOUNT":
      return "계좌번호 등록/ 휴대폰 번호 변경"
    default:
      return category
  }
}

export const parsePendingOTPMessage = (category: string) => {
  return `${HAS_PENDING_OTP}\n( ${parseCategory(category)} )${category === "PLAYER_BANK_ACCOUNT" && "\n잠시 후에 다시 시도해주세요."}`
}
